// src/LandingPage.js
import React, { useState, useEffect, useRef } from 'react';
import CountdownTimer from './CountdownTimer.js';
import { FiChevronDown, FiChevronUp, FiArrowRight, FiPlus, FiInstagram, FiTwitter, FiUser, FiGlobe, FiYoutube, FiFacebook, FiX, FiTrendingUp, FiBox, FiUsers, FiGrid} from "react-icons/fi";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

function FAQItem({ item }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={`border border-[#0b062b] rounded-lg overflow-hidden transition-all text-[#0b062b] hover:text-white ${
        isOpen ? 'bg-[#0b062b] text-white' : 'bg-white text-[#0b062b]'
      }`}
      style={{
        height: isOpen ? "200px" : "120px",
        transition: "height 0.5s ease",
      }}
    >
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`w-full ${isOpen ? '' : 'h-full'} flex justify-between items-center text-xl px-4 py-4 hover:bg-[#0b062b] focus:outline-none`}
      >
        <span className="text-left font-medium">{item.question}</span>
        <FiPlus
          className={`w-6 h-6 transition-transform duration-500 transform ${
            isOpen ? 'rotate-45' : 'rotate-0'
          }`}
        />
      </button>
      <div
        className={`px-4 pb-4 text-white ${
          isOpen ? "block" : "hidden"
        } transition-all duration-500`}
      >
        {item.answer}
      </div>
    </div>
  );
}

const LandingPage = () => {
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const sectionsRefs = useRef([]);

  // Fade in the page on load
  useEffect(() => {
    setIsPageLoaded(true);

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("fade-in-visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    sectionsRefs.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => observer.disconnect();
  }, []);

  const teamMembers = [
    { id: 1, name: 'Matthews Farias', description: 'Lead Strategist and Expert in Market Analysis.' },
  ];

  const instructorImages = [
  '/image/matt.jpg',
  '/image/matt2.jpg',
];

  const faqData = [
    {
      question: "What is Trend Traders Academy?",
      answer:
        "Trend Traders Academy Is a Private Group Dedicated to Day & Swing Trading, With A Commitment To Educating Members For making Informed Market Decisions.",
    },
    {
      question: "What Assets are Traded inside TTA?",
      answer:
        "Our Primary Focus Is On Trading Indices On Futures, Especially ES. Though We Frequently Trade The Equities Market Including Options.",
    },
    {
      question: "How often are the Live Streams inside TTA?",
      answer:
        "Everyday Our Instructors Deem A Trading Day, We Stream, We Mainly Stream New York (9:00 EST) And Occasionally Globex Session.",
    },
    {
      question: "What's your return policy?",
      answer:
        "We Do Not Offer Refunds Of Any Of Our Mentorships Or Products All Payments Are Final.",
    },
    {
      question: "Do you offer signals?",
      answer:
        "NO. We Do Not Offer Signals At All. Please Do Not Sign Up If You Are Looking For Signals.",
    },
    {
      question: "What is the TTA Model?",
      answer:
        "The TTA Model Is A Trading Model That Is Founded By Mathews & Actively Traded Inside Of The Community. Its Model That’s Been Extensively Backtested And Documented Inside Trend Traders Academy.",
    },
    // Add more FAQ items as needed
  ];

  const reviews = [
    {
      name: "Mareio",
      image: "https://img-v2-prod.whop.com/14LVFAuWuq-AL1dQXVOIrhvdawoPAXjJrfhTYLBUGfc/rs:fill:32:32/el:1/aHR0cHM6Ly9hc3NldHMud2hvcC5jb20vdXBsb2Fkcy8yMDI0LTA4LTI3L3VzZXJfNTg4NzkxOF83MGQ1NWM4Zi02ZDE5LTQwNTMtYWZhZC1mNmI3NTZjN2M0Y2QucG5n",
      review: "I wasted many years learning useless concepts and strategies. Thankfully my journey eventually took me here, where I learned everything I trade with now. Matt continuously steered me on the right path when I made mistakes. He helped me understand the simplicity of trading and how to apply his strategy effectively. When I took this course, I threw most of my prior trading knowledge away and completely focused on everything being taught. After much dedication and hard work, I have became an amazing trader. This course will teach you everything you need. You just need to put in the work.",
    },
    {
      name: "Andrew",
      image: "https://img-v2-prod.whop.com/nsd-whgbMMXJbovI3Q6Vq9DpfyyfWxy2RfACD3g7pMU/rs:fill:32:32/el:1/aHR0cHM6Ly9jZG4uZGlzY29yZGFwcC5jb20vYXZhdGFycy80MDczOTcwOTgyMDQ2OTI0ODIvNjI1MGJkN2E5ZDE5NGE2ZGMxYjRlMmZlODU0M2RmMTg",
      review: "Matt's been my mentor for the last 2 years and I feel so lucky tbh. The industry of trading is super scammy and shady, but Matt is a small, authentic, REAL TRADER. His lifestyle shows his success. He's been doing this for 8 years and his trading strategy is REAL. Since Matt's mentorship I have passed a funded account and am getting my first payout in a few days. I was debating buying the bootcamp, but I am so happy I did. The teaching is crystal clear, and MAKES SENSE. Great bootcamp, you will not find anything close to this level of mentorship/strategy on the internet anywhere.",
    },
    {
      name: "Keanu",
      image: "https://cdn.discordapp.com/avatars/777532879550677022/ea885b98feb65224763a95c1e8874ce1.webp?size=128",
      review: "Been with Mathews for a little over a year now and I can honestly say that there is no better mentor out there to learn from. The strategy he masters works like clockwork and once you understand the way he works, you'll realize that everything you learned or tried before is just a bunch of ........ What he teaches is nowhere else to be found and it will change your life forever! You just have to be willing to forget everything else you might have learned from these ‘profitable trading furu’s’ and put in the work!",
    },
    {
      name: "Gregg",
      image: "https://img-v2-prod.whop.com/7JKD0P4OCbVPwQUWK3mtb-V22akNzW-RoccUjYJunTA/rs:fill:32:32/el:1/aHR0cHM6Ly91aS1hdmF0YXJzLmNvbS9hcGkvP25hbWU9R3JlZ29yeSUyMEdhcnJpY2smYmFja2dyb3VuZD01MzU5NjEmY29sb3I9ZmZmJmZvcm1hdD1wbmc",
      review: "Trend Traders Academy crash course has been a real eye opener. Matt’s way of doing trend analysis is really helpful to understand what the market is doing and which direction we should be trading. I would definitely recommend it to anyone looking to improve their trading and get an extra edge.",
    },
    {
      name: "Jeff",
      image: "https://img-v2-prod.whop.com/iYtOTpD1EU-w30Cyze4tQsw3Uj5tyj7IxJIaMi4HkXE/rs:fill:32:32/el:1/aHR0cHM6Ly9jZG4uZGlzY29yZGFwcC5jb20vYXZhdGFycy8zMTI5NzM5MjA2NDUwMjE2OTcvMzJlZDk4MDE2MmU0MzJmYjdkNTFmOGM2M2MxODMwNjI",
      review: "As someone who has been trading for about a year and a half, I can honestly say this course has impressed me. This course is setup to help beginner traders who are completely new to trading or traders with a bit of experience who already have formed their own strategy and are just looking for that missing piece. Matt does a wonderful job of structuring his course to fit all levels of experience. The learning is made easier with a ton of videos structured in a shorter time frame to allow people who don't like staring at a screen for hours to stay focused. Overall, Matthews truly cares about his craft and learning his perspective on how the market moves has allowed me more confidence in the markets and greatly improved my ability to see daily and hourly trend more clearly along with refining my own areas of entries and exits.",
    },
    {
      name: "Rucker",
      image: "https://img-v2-prod.whop.com/7amCQ-ESiuNKVfsEZDqtm213MiTo8cEw8mswpaE9ylg/rs:fill:32:32/el:1/aHR0cHM6Ly9hc3NldHMud2hvcC5jb20vdXBsb2Fkcy8yMDI0LTA5LTE0L3VzZXJfMzI4NTQxMV84Y2QyMTY1NC1lM2NmLTQwNzAtOTE3ZC0zMjllYTgyZDgxOTAuanBlZw",
      review: "I had struggled for a long time with different trading strategies. Until this course I didn't fully understand supply and demand trading. This course lays it out step by step and was finally simple enough for me to make progress. If I could go back I would've saved myself years and lots of money in trading by just signing up for this mastermind before anything else.",
    },
  ];

const features = [
  {
    title: 'Supply & Demand Strategy',
    description: 'Master our rule-based strategy to identify top-quality supply and demand zones confidently, with exclusive insights and tools for accurate trading.',
    icon: <FiTrendingUp className="w-8 h-8 text-[#0b062b]" />, // Added icon
  },
  {
    title: 'Trend Box Analysis',
    description: 'Use our simple, powerful technique to pinpoint market trends and align with market direction, boosting trade confidence and precision.',
    icon: <FiBox className="w-8 h-8 text-[#0b062b]" />, // Added icon
  },
  {
    title: 'Live Trading & Community',
    description: 'Engage in live sessions with TTA instructors, open discussions, and a global community of traders for collaborative growth.',
    icon: <FiUsers className="w-8 h-8 text-[#0b062b]" />, // Added icon
  },
  {
    title: 'TTA Grid Zones',
    description: 'Get daily insights and in-depth market analyses from our expert team to enhance your trading edge.',
    icon: <FiGrid className="w-8 h-8 text-[#0b062b]" />, // Added icon
  },
];

  const membershipOptions = [
    { title: 'Free Discord', price: 'FREE', benefits: ['Free Webinars', 'Active Trading Community'], best: false, countdown: false, url: "https://whop.com/checkout/plan_2ukvrFA5wBKoo?d2c=true" },
          {
      title: 'Mastermind 2.0',
      price: '$2499',
      url: 'https://whop.com/checkout/plan_49GodRDfuNRkc?d2c=true',
      benefits: [
        "Complete supply and demand strategy",
        "Trend Box analysis technique",
        "Multi time frame analysis",
        "Trade scoring system",
        "TTA trading models",
        "Full risk management strategies",
        "Lifetime access to TTA Paid Discord",
        "8 scheduled live sessions",
      ],
      best: true,
    countdown: true,
    targetDate: '2024-11-30T23:59:59', // Example target date
    },
    {
      title: 'Crash Course',
      price: '$349',
      url: 'https://whop.com/checkout/plan_cRMnwaP1dfPEA?d2c=true',
      benefits: [
        "Master the Trend Box analysis technique",
        "Identify market trends with ease",
        "Intro to supply and demand",
        "Risk management strategies",
        "Learn the proper way to backtest",
      ],
      best: false,
      countdown: false,
    },
  ];

  return (
    <div className={`min-h-screen bg-white text-gray-900 ${isPageLoaded ? 'fade-in' : ''}`}>
      {/* Navigation */}
      <nav className="fixed top-0 left-0 w-full bg-[#0b062b] p-4 z-50">
        <div className="container mx-auto flex justify-between items-center">
          <img src="/image/tta-2.png" alt="Logo" className="h-16 sm:h-20" />
          <button
            onClick={() => window.open("https://whop.com/trend-traders-academy/", "_blank")}
            className="group bg-[#370303] text-white py-2 px-4 rounded-md hover:bg-[#072702] transition flex items-center"
          >
            <span className="flex items-center">
              Get Access
              <FiArrowRight className="ml-2 duration-500 transform group-hover:translate-x-2" />
            </span>
          </button>
        </div>
      </nav>

      <div className="mt-24">
        {/* Hero Section */}
        <section className="flex flex-col items-center justify-center text-center py-16 px-4 sm:py-20 sm:px-6 bg-[#0b062b] text-white">
          <h1 className="text-4xl sm:text-5xl lg:text-6xl font-extrabold mb-4">Trend Traders Academy</h1>
          <p className="text-md sm:text-lg mb-8 max-w-lg">
            Excel In Navigating Market Trends
          </p>
                    <button
            onClick={() => window.open("https://whop.com/trend-traders-academy/", "_blank")}
            className="group bg-[#370303] text-white py-2 px-4 rounded-md hover:bg-[#072702] transition flex items-center"
          >
            <span className="flex items-center">
              Join us
              <FiArrowRight className="ml-2 duration-500 transform group-hover:translate-x-2" />
            </span>
          </button>
        </section>

        {/* Features Section */}
<section id="features" className="py-12 cursor-default sm:py-16 px-4 sm:px-6 bg-gray-100 fade-in-hidden" ref={(el) => sectionsRefs.current[2] = el}>
  <div className="container mx-auto text-center">
    <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-6 text-[#0b062b]">Features</h2>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8">
      {features.map((feature, index) => (
        <div
          key={index}
          className="bg-white p-6 rounded-lg shadow-md border border-[#0b062b] hover:bg-[#e1e3ea] transition duration-500 flex items-start"
        >
          {/* Icon */}
          <div className="mr-4">
            {feature.icon}
          </div>
          {/* Content */}
          <div>
            <h3 className="text-xl sm:text-2xl font-bold mb-2 text-black">{feature.title}</h3>
            <p className="text-black">{feature.description}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
</section>

        {/* Team Section */}
        {/* Team Section */}
        <section id="our-team" className="py-12 sm:py-16 px-4 sm:px-6 bg-gray-100" ref={(el) => sectionsRefs.current[3] = el}>
          <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-7 text-[#0b062b] text-center">Your Instructor</h2>
          <div className="container mx-auto flex flex-col sm:flex-row items-center text-center sm:text-left">
            {/* Image Container */}
            <div className="w-full sm:w-1/2 flex justify-center sm:justify-center mb-6 sm:mb-0 sm:mr-6">
              <div className="w-72 h-72 sm:w-64 sm:h-80 lg:w-96 lg:h-96 relative rounded-lg overflow-hidden">
                {/* Swiper Carousel */}
                <Swiper
                  modules={[Navigation, Pagination, Autoplay]}
                  spaceBetween={10}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  autoplay={{ delay: 5000, disableOnInteraction: false }}
                  loop
                  className="h-full"
                >
                  {instructorImages.map((image, index) => (
                    <SwiperSlide key={index}>
                      {image ? (
                        <img
                          src={image}
                          alt={`Mathews Farias ${index + 1}`}
                          className="w-full h-full object-cover"
                          loading="lazy"
                        />
                      ) : (
                        <FiUser className="w-24 h-24 text-gray-400 mx-auto" />
                      )}
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
            {/* Text Container */}
            <div className="w-full sm:w-1/2 p-4 sm:p-6">
              {/* Flex container for name and socials */}
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-2xl sm:text-3xl font-semibold text-black">Mathews Farias</h3>
                <div className="flex space-x-3 text-2xl sm:text-3xl font-semibold text-black">
                  <FiInstagram
                    className="hover:text-[#370303] transition duration-500 cursor-pointer"
                    onClick={() => window.open("https://www.instagram.com/mathewsfariass", "_blank")}
                    aria-label="Instagram"
                  />
                  <FiYoutube
                    className="hover:text-[#370303] transition duration-500 cursor-pointer"
                    onClick={() => window.open("https://www.youtube.com/@MathewsFarias", "_blank")}
                    aria-label="YouTube"
                  />
                </div>
              </div>
              <p className="text-gray-700">
                Mathews Farias is a seasoned trader with over 7 years of experience in the financial markets, renowned for his strategic insights and commitment to helping fellow traders achieve excellence. Known for his low-risk, high-reward trading Models, Mathews’ strategies are data-proven through thousands of hours of rigorous backtesting and live trade logs. His practical, results-driven approach emphasizes accuracy and consistency, empowering traders to identify high-probability opportunities with confidence and achieve lasting success in the markets.
              </p>
            </div>
          </div>
        </section>

        {/* FAQ Section */}
        <section
          id="faq"
          className="py-12 sm:py-16 px-4 sm:px-6 bg-white"
          ref={(el) => (sectionsRefs.current[6] = el)}
        >
          <div className="container mx-auto">
            <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-8 text-[#0b062b] text-center">
              Frequently Asked Questions
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              {faqData.map((item, index) => (
                <FAQItem key={index} item={item} />
              ))}
            </div>
          </div>
        </section>

        {/* Members Section */}
        <section id="our-members" className="py-12 sm:py-16 px-4 sm:px-6 bg-white" ref={(el) => sectionsRefs.current[4] = el}>
          <div className="container mx-auto text-center">
            <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-6 text-[#0b062b]">Our Members</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
              {reviews.map((option, index) => (
                <div key={index} className="bg-white p-6 rounded-lg shadow-md border border-[#0b062b]">
                  <div className="flex justify-between items-center mb-2">

                    <p className="text-black font-bold">
                      {option.image ? (
            <img
              src={option.image}
              alt={option.name}
              className="w-9 h-9 flex inline-flex mr-2 rounded-full"
            />
          ) : (
            <FiUser className="w-9 h-9 flex inline-flex mr-2 rounded-full text-gray-400" />
          )}{option.name}</p>
                    <span className="text-yellow-500 text-xl">★★★★★</span>
                  </div>
                  <p className="text-black">{option.review}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Pricing Section */}
        <section id="pricing" className="py-12 sm:py-16 px-4 sm:px-6 bg-gray-100" ref={(el) => sectionsRefs.current[5] = el}>
  <div className="container mx-auto text-center">
    <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-6 text-[#0b062b]">Pricing</h2>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 justify-center gap-12">
      {membershipOptions.map((option, index) => (
        <div
          onClick={() => window.open(option.url, "_blank")}
          key={index}
          className={`group cursor-pointer p-6 rounded-lg shadow-md border ${
            option.best ? 'bg-[#e1e3ea] border-4 border-[#370303] hover:border-[#072702]' : 'bg-white border-gray-200'
          } flex flex-col justify-between transition duration-500 w-full sm:w-80 lg:w-full`}
        >
          <div>
            <h3 className="text-xl sm:text-2xl font-bold text-[#0b062b] mb-2">{option.title}</h3>
            {/* Conditionally render the Countdown Timer */}
            <p className="text-lg sm:text-xl font-semibold text-black mb-4">{option.price}</p>
            <ul className="text-left mb-4">
              {option.benefits.map((benefit, i) => (
                <li
                  key={i}
                  className="flex items-center mb-2 transform hover:translate-x-2 duration-500"
                >
                  <span className="text-green-500 mr-2">✓</span>
                  <span className="text-black">{benefit}</span>
                </li>
              ))}
            </ul>
            {option.countdown && option.targetDate && (
              <CountdownTimer targetDate={option.targetDate} />)
            }
          </div>
          <button
            className="bg-[#370303] text-white py-2 px-4 w-full rounded-md transition mt-4 group-hover:bg-[#072702] duration-500"
          >
            Get Access
          </button>
        </div>
      ))}
    </div>
  </div>
</section>



        <div className={"h-full pt-5 pb-2 bg-[#0b062b] text-white"}>
        <div className={"flex justify-center my-2"}>
          <FiInstagram
              className={"h-7 cursor-pointer w-7 mx-5 hover:text-[#370303] transition duration-500"}
              onClick={() => window.open("https://www.instagram.com/trendtraderacademy/", "_blank")}
          />
          {/*<FiFacebook className={"h-7 w-7 mx-5 hover:text-[#370303] transition duration-500"}*/}
          {/*onClick={() => window.open("https://whop.com/trend-traders-academy/", "_blank")}/>*/}
          {/*<FiX className={"h-7 w-7 mx-5 hover:text-[#370303] transition duration-500"}*/}
          {/*onClick={() => window.open("https://whop.com/trend-traders-academy/", "_blank")}/>*/}
          <FiGlobe className={"h-7 cursor-pointer w-7 mx-5 hover:text-[#370303] transition duration-500"}
          onClick={() => window.open("https://whop.com/trend-traders-academy/", "_blank")}/>
        </div>
      </div>
    <div className={"bg-[#0b062b] text-white pb-2"}>Trend Traders Academy © 2025</div>
    </div>
      </div>
  );
};

export default LandingPage;
